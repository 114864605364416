<template>
  <SiteHeader />
  <HeroSection />
  <main id="main">
    <AboutSection />
    <ServicesSection />
    <CallToActionSection/>
    <PricingSection/>
    <FaqSection />
    <ContactSection />
    
  </main>
  <SiteFooter />
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import HeroSection from './components/sections/HeroSection.vue'
import AboutSection from './components/sections/AboutSection.vue'
import ServicesSection from './components/sections/ServicesSection.vue'
import CallToActionSection from './components/sections/CallToActionSection.vue'
import FaqSection from './components/sections/FaqSection.vue'
import ContactSection from './components/sections/ContactSection.vue'
//import SocialFeedSection from './components/sections/SocialFeedSection.vue'
import SiteFooter from './components/SiteFooter.vue'
import PricingSection from './components/sections/PricingSection.vue'
//import ReviewsSection from './components/sections/ReviewsSection.vue'

export default {
  name: 'App',
  components: {
    HeroSection,
    SiteHeader,
    AboutSection,
    ServicesSection,
    CallToActionSection,
    PricingSection,
    FaqSection,
    ContactSection,
    SiteFooter
  }
}
</script>

<style>
</style>