<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container">

      <div class="section-title">
        <h2>Contact</h2>
      </div>
    </div>

    <div>
      <iframe style="border:0; width: 100%; height: 350px;"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3183.739454524742!2d-93.23231419999999!3d37.063691600000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87cf7069717ca691%3A0xa10d958c077d4055!2s5000%20N%2022nd%20St%2C%20Ozark%2C%20MO%2065721!5e0!3m2!1sen!2sus!4v1738290254573!5m2!1sen!2sus"
        frameborder="0" allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <!-- Place <div> tag where you want the feed to appear -->
    <div class="container">
      <div class="row mt-5">
        <div class="">
          <div class="row info">
            <div class="col-lg-4 col-md-6 hours">
              <i class='bx bx-time'></i>
              <h4>Hours:</h4>
              <p>By appointment only.</p>
            </div>

            <div class="col-lg-4 col-md-6 address">
              <i class='bx bx-map-pin'></i>
              <h4>Location:</h4>
              <p>5000 N 22nd St Ozark Mo, 65721</p>
              <p>(Located inside the 417 female)</p>
            </div>

            <div class="col-lg-4 col-md-6 email">
              <i class='bx bx-envelope'></i>
              <h4>Email:</h4>
              <p>allie@chasingwellnesspt.com</p>
            </div>

            <div class="col-lg-4 col-md-6 phone">
              <i class='bx bx-phone'></i>
              <h4>Call or Text:</h4>
              <p>417-233-4331</p>
            </div>

            <div class="col-lg-4 col-md-6 fax">
              <i class='bx bx-printer'></i>
              <h4>Fax:</h4>
              <p>417-459-4870</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section><!-- End Contact Section -->
</template>

<style lang="scss">
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
  background: $white;

  .info {
    width: 100%;
    background: $white;

    i {
      font-size: 32px;
      color: $primary;
      float: left;
      line-height: 1;
    }

    h4 {
      padding: 0 0 0 60px;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 5px;
      color: $secondary;
    }

    p {
      padding: 0 0 0 60px;
      margin-bottom: 0;
      font-size: 14px;
      color: lighten($secondary, 20);
    }

    .hours,
    .email,
    .phone,
    .address,
    .fax {
      margin-top: 40px;
    }
  }


  .email-form {
    width: 100%;
    background: #fff;

    .form-group {
      padding-bottom: 8px;

      &.has-danger {
        padding-bottom: 0px;

        .form-control {
          border-bottom: 2px solid #dc1d34;
        }
      }

      .text-help {
        color: #dc1d34;
        font-size: small;
        padding-left: 5px;
      }
    }

    #sent-error {
      display: none;
      color: #ed3c0d;
      text-align: center;
      padding: 10px 24px;
      font-weight: 600;

      & br+br {
        margin-top: 25px;
      }
    }

    #sent-message {
      display: none;
      color: #18d26e;
      text-align: center;
      padding: 10px 24px;
      font-weight: 600;
    }

    .loading {
      display: none;
      background: #fff;
      text-align: center;
      padding: 15px;

      &:before {
        content: "";
        display: inline-block;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin: 0 10px -6px 0;
        border: 3px solid #18d26e;
        border-top-color: #eee;
        animation: animate-loading 1s linear infinite;
      }

    }

    input,
    textarea {
      border-radius: 0;
      box-shadow: none;
      font-size: 14px;
      border-radius: 4px;

      &:focus {
        border-color: $primary;
      }
    }

    input {
      height: 44px;
    }

    textarea {
      padding: 10px 12px;
    }

    button {
      background: $primary;
      border: 0;
      padding: 10px 24px;
      color: #fff;
      transition: 0.4s;
      border-radius: 4px;

      &:hover {
        background: lighten($primary, 10%);
      }
    }

  }

  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>